import * as api from './http'

/* 购物车列表 */
/* export function order_detail(params){
    return api.postApi('goods/order_detail', params)
} */

/* 订单列表 */
export function order_list(params){
    return api.postApi('goods/order_list', params)
}

/* 订单详情 */
export function order_detail(params){
    return api.postApi('goods/order_detail', params)
}

/* 订单详情 */
export function cancel_order(params){
    return api.postApi('goods/cancel_order', params)
}

/* 申请退款 */
export function order_refund(params){
    return api.postApi('goods/order_refund', params)
}

/* 订单评价 */
export function order_evaluate(params){
    return api.postApi('goods/order_evaluate', params)
}

/* 申请售后 */
export function order_after(params){
    return api.postApi('goods/order_after', params)
}

/* 确认收货 */
export function confirm_receipt(params){
    return api.postApi('goods/confirm_receipt', params)
}

/* 查看物流 */
export function get_logistics(params){
    return api.postApi('goods/get_logistics', params)
}