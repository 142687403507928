<template>
    <div class="orderList">
        <div class="orderTypeList flex alignCenter">
            <span v-for="(im,ix) in orderTypeList" :key="ix" :class="ordertypeindex == ix ? 'typeCurr' : ''" @click="ordertypeindex = ix">{{im.title}}</span>
        </div>
        <div class="orderInfoList">
            <div class="orderInfo" v-for="(im,ix) in orderList[page-1]" :key="ix">
                <div class="orderInfoTitle flex alignCenter spacebetween">
                    <div class="orderInfoTitleLeft flex c33">
                        <div style="width:195px;text-align:left;">订单号: {{im.order_sn}}</div>
                        <div style="width:271px;margin-left:30px;text-align:left;">下单时间: {{im.ctime}}</div>
                    </div>
                    <div class="orderInfoTitleRight">
                        订单状态：{{im.status == 0 ? '待付款': im.status == 1 ? '已付款' :  im.status == 2 ? '已发货' : im.status == 3 ? '已收货' : im.status == 4 ? '已评价' : im.status == 5 ? '已取消' : im.status == 6 ? '申请退款 ' : im.status == 7 ? '同意退款' : im.status == 8 ? '完成退款 ' : im.status == 9 ? '商家拒绝退款 '  : '申请售后中 ' }}
                    </div>
                </div>
                <div class="orderInfoBottom flex alignCenter" v-for="(jm,jx) in im.goods_order" :key="jx">
                    <div class="orderInfoBottomLeft flex alignCenter">
                        <img style="cursor:pointer;" @click="goother({path:'/shopdetail', query:{id: jm.goods_id}})" :src="$store.getters.Baseurl + jm.goods_cover" alt="">
                        <div style="cursor:pointer;" @click="goother({path:'/shopdetail', query:{id: jm.goods_id}})" class="goods_orderTitle">{{jm.goods_name}}</div>
                        <div class="goods_orderPrice">{{jm.integral}}积分 + ￥{{jm.price}}</div>
                        <div class="goods_orderNum">X {{jm.num}}</div>
                    </div>
                    <div class="orderInfoBottomCenter flex flexColumn spacearound alignCenter">
                        <div style="min-height:40px;" class="flex flexColumn alignCenter">
                            <span style="font-size:13px;color:#666666;">总金额</span>
                            {{(jm.integral * jm.num).toFixed(2)}}积分 + ￥{{(jm.price * jm.num).toFixed(2)}}
                        </div>
                    </div>
                    <div class="orderInfoBottomRight flex flexColumn spacearound alignCenter">
                        <button v-show="im.status == 1" @click="refund(im)">申请退款</button>
                        <span @click="showDetail(im)">查看详情</span>
                        <span v-show="im.status == 0 && popup_goods_order.is_close != 0" @click="cancleOrder(im)">取消订单</span>
                        <span v-show="im.status == 2" @click="readLog(im)">查看物流</span>
                        <span v-show="im.status == 2" @click="aftersale(im)">申请售后</span>
                        <span v-show="im.status == 2 || im.status == 9" @click="frimShop(im)">确认收货</span>
                        <span v-show="im.status == 3" @click="evaluate(jm,im)">去评价</span>
                    </div>
                </div>
            </div>

            <!-- <temp-data v-show="orderList[page-1].length < 1"></temp-data> -->
        </div>

        <el-pagination
        v-if="orderNumber > 0"
        style="margin-top:37px;"
        background
        layout="prev, pager, next"
        :total="orderNumber"
        :page-size="10"
        @current-change="currentChange">
        </el-pagination>

        <!-- 订单详情 -->
        <el-dialog
        :visible.sync="showOrderDetail"
        width="1100px"
        center>
        <div class="orderDetail marginAuto scrollHidden">
            <div class="orderDetailTop">
                <div class="orderDetailTitle flex alignCenter">
                    <div style="margin-left: 24px;">订单号：{{popup_goods_order.order_sn}}</div>
                    <div style="margin-left: 24px;">订单状态：{{popup_goods_order.status == 0 ? '待付款': popup_goods_order.status == 1 ? '已付款' :  popup_goods_order.status == 2 ? '已发货' : popup_goods_order.status == 3 ? '已收货' : popup_goods_order.status == 4 ? '已评价' : popup_goods_order.status == 5 ? '已取消' : popup_goods_order.status == 6 ? '申请退款 ' : popup_goods_order.status == 7 ? '同意退款' : popup_goods_order.status == 8 ? '完成退款 ' : popup_goods_order.status == 9 ? '商家拒绝退款 '  : '申请售后中 ' }}</div>
                </div>
                <div class="orderDetailInfo flex flexColumn">
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            支付方式：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.pay_type == 1 ? '微信' : popup_goods_order.pay_type == 2 ? '支付宝' : popup_goods_order.pay_type == 3 ? '积分' : '未付款'}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            支付流水号：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.order_sn}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            下单时间：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.ctime}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            订单备注：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.remark}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="orderDetailTop" style="margin-top:17px;height:185px;">
                <div class="orderDetailTitle flex alignCenter">
                    <div style="margin-left: 24px;">收货人信息</div>
                </div>
                <div class="orderDetailInfo flex flexColumn">
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            收货人姓名：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.address_name}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            地址：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.address}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            联系方式：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.address_phone}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="orderDetailTop" style="margin-top:17px;height:185px;" v-show="popup_goods_order.express_name && popup_goods_order.express_num">
                <div class="orderDetailTitle flex alignCenter">
                    <div style="margin-left: 24px;">物流信息</div>
                </div>
                <div class="orderDetailInfo flex flexColumn">
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            物流公司：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.express_name}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            快递单号：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.express_num}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="orderGoods">
                <div class="orderGoodsTitle flex alignCenter c33">
                    <div class="orderGoods_goods flex alignCenter spacearound" style="">商品</div>
                    <div class="orderGoods_price flex alignCenter spacearound">单价</div>
                    <div class="orderGoods_num flex alignCenter spacearound">数量</div>
                    <div class="orderGoods_total flex alignCenter spacearound">总价</div>
                </div>
                <div class="orderGoodsList flex alignCenter" v-for="(im,ix) in popup_goods_order.goods_order" :key="ix">
                    <div class="orderGoods_goods orderGoodsList_goods flex alignCenter">
                        <img style="cursor:pointer;" @click="goother({path:'/shopdetail', query:{id: im.goods_id}})" :src="$store.getters.Baseurl + im.goods_cover" alt="">
                        <div style="cursor:pointer;" @click="goother({path:'/shopdetail', query:{id: im.goods_id}})" class="orderGoodsList_goodsname">{{im.goods_name}}</div>
                    </div>
                    <div class="orderGoods_price flex alignCenter spacearound" style="color:#F03708;font-size:16px;">{{(im.integral * im.num).toFixed(2)}}积分 + ￥{{(im.price * im.num).toFixed(2)}}</div>
                    <div class="orderGoods_num flex alignCenter spacearound">{{im.num}}</div>
                    <div class="orderGoods_total flex alignCenter spacearound" style="color:#F03708;font-size:16px;">{{(im.integral * im.num).toFixed(2)}}积分 + ￥{{(im.price * im.num).toFixed(2)}}</div>
                </div>
                <div class="orderShopInfo flex flexColumn">
                    <div class="orderShopInfoPrice">快递费用： <span>￥{{popup_goods_order.express}}</span></div>
                    <div class="orderShopInfoPrice">应付金额： <span>￥{{orderDetailTotal}}</span></div>
                    <div class="orderShopInfoBtn flex">
                        <button class="cancleOrder" @click="cancleOrder(popup_goods_order)" v-show="popup_goods_order.status == 0 && popup_goods_order.is_close != 0">取消订单</button>
                        <button class="orderPay" @click="orderPay(popup_goods_order)" v-show="popup_goods_order.status == 0  && popup_goods_order.is_close != 0">去支付</button>
                        <button class="orderPay" @click="refund" v-show="popup_goods_order.status == 1">申请退款</button>
                        <button class="orderPaynbg" @click="readLog(popup_goods_order)" v-show="popup_goods_order.status == 2">查看物流</button>
                        <button class="orderPaynbg" @click="aftersale(popup_goods_order)" v-show="popup_goods_order.status == 2">申请售后</button>
                        <button class="orderPay" @click="frimShop(popup_goods_order)" v-show="popup_goods_order.status == 2 || popup_goods_order.status == 9">确认收货</button>
                        <!-- <button class="orderPay" @click="refund" v-show="popup_goods_order.status == 3">去评价</button> -->
                    </div>
                </div>
            </div>
        </div>
        </el-dialog>

        <!-- 申请退款 -->
        <el-dialog
        :visible.sync="refundShow"
        width="1100px"
        center>
        <div class="orderDetail marginAuto scrollHidden">
            <div class="orderDetailTop">
                <div class="orderDetailTitle flex alignCenter">
                    <div style="margin-left: 24px;">订单号：{{popup_goods_order.order_sn}}</div>
                    <div style="margin-left: 24px;">订单状态：{{popup_goods_order.status == 0 ? '待付款': popup_goods_order.status == 1 ? '已付款' :  popup_goods_order.status == 2 ? '已发货' : popup_goods_order.status == 3 ? '已收货' : popup_goods_order.status == 4 ? '已评价' : popup_goods_order.status == 5 ? '已取消' : popup_goods_order.status == 6 ? '申请退款 ' : popup_goods_order.status == 7 ? '同意退款' : popup_goods_order.status == 8 ? '完成退款 ' : popup_goods_order.status == 9 ? '商家拒绝退款 '  : '申请售后中 ' }}</div>
                </div>
                <div class="orderDetailInfo flex flexColumn">
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            支付方式：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.pay_type == 1 ? '微信' : popup_goods_order.pay_type == 2 ? '支付宝' : popup_goods_order.pay_type == 3 ? '积分' : '未付款'}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            支付流水号：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.order_sn}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            下单时间：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.ctime}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            订单备注：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.remark}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="orderRefund">
                <textarea name="" id="" v-model="reason.reason_describe" style="resize:none" placeholder="退款原因描述"></textarea>
            </div>
            <div class="orderRefundImg flex">
                <div class="orderRefundImgList" v-for="(im,ix) in upimgList" :key="ix">
                    <img :src="$store.getters.Baseurl + im" alt="">
                    <div @click="removeImg(ix)" class="removeupimg">删除</div>
                </div>
                <uploadimg @getimg="getimg" :impose="false" style="margin-right:16px;">
                    <div class="upImg flex alginCenter spacearound">+</div>
                </uploadimg>
            </div>
            <button class="refundSub" @click="refundSub(popup_goods_order)">提交</button>
        </div>
        </el-dialog>

        <el-dialog
        :visible.sync="evaluateShow"
        width="500px"
        center>
        <div class="orderDetail">
            <div class="shopInfo flex">
                <img :src="$store.getters.Baseurl + evaluateInfo.goods_cover" alt="">
                <div class="shoInfoName flex flexColumn spacebetween">
                    <div>{{evaluateInfo.goods_name}}</div>
                    <div class="flex">商品打分<el-rate style="margin-left:20px;" v-model="shopstars"></el-rate></div>
                </div>
            </div>
            <div class="evaluateText">
                <textarea name="" id="" style="resize:none" v-model="shopcontent" placeholder="来分享下你的感受"></textarea>
            </div>
            <div class="orderRefundImg flex">
                <div class="orderRefundImgList" v-for="(im,ix) in upimgList" :key="ix">
                    <img :src="$store.getters.Baseurl + im" alt="">
                    <div @click="removeImg(ix)" class="removeupimg">删除</div>
                </div>
                <uploadimg @getimg="getimg" :impose="false" style="margin-right:16px;">
                    <div class="upImg flex alginCenter spacearound">+</div>
                </uploadimg>
            </div>
            <button class="evalSub" @click="evalSub(evaluateInfo)">提交</button>
        </div>
        </el-dialog>

        <!-- 申请售后 -->
        <el-dialog
        :visible.sync="aftersaleHidden"
        width="1100px"
        center>
        <div class="orderDetail marginAuto scrollHidden">
            <div class="orderDetailTop">
                <div class="orderDetailTitle flex alignCenter">
                    <div style="margin-left: 24px;">订单号：{{popup_goods_order.order_sn}}</div>
                    <div style="margin-left: 24px;">订单状态：{{popup_goods_order.status == 0 ? '待付款': popup_goods_order.status == 1 ? '已付款' :  popup_goods_order.status == 2 ? '已发货' : popup_goods_order.status == 3 ? '已收货' : popup_goods_order.status == 4 ? '已评价' : popup_goods_order.status == 5 ? '已取消' : popup_goods_order.status == 6 ? '申请退款 ' : popup_goods_order.status == 7 ? '同意退款' : popup_goods_order.status == 8 ? '完成退款 ' : popup_goods_order.status == 9 ? '商家拒绝退款 '  : '申请售后中 ' }}</div>
                </div>
                <div class="orderDetailInfo flex flexColumn">
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            支付方式：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.pay_type == 1 ? '微信' : popup_goods_order.pay_type == 2 ? '支付宝' : popup_goods_order.pay_type == 3 ? '积分' : '未付款'}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            支付流水号：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.order_sn}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            下单时间：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.ctime}}
                        </div>
                    </div>
                    <div class="orderDetailInfoPay flex">
                        <div class="orderDInfoLeft">
                            订单备注：
                        </div>
                        <div class="orderDInfoRight">
                            {{popup_goods_order.remark}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="aftersaleReason flex alignCenter" style="margin-top:20px;">
                <span style="font-size:16px;color:#333;font-weight:bold;">售后原因</span>
                <el-select style="margin-left:25px;border:none" v-model="afterSaleSel" placeholder="请选择售后原因">
                    <el-option
                        v-for="item in afterSale"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select>
            </div>

            <div class="orderRefund">
                <textarea name="" id="" v-model="reason.reason_describe" style="resize:none" placeholder="退款原因描述"></textarea>
            </div>
            <div class="orderRefundImg flex">
                <div class="orderRefundImgList" v-for="(im,ix) in upimgList" :key="ix">
                    <img :src="$store.getters.Baseurl + im" alt="">
                    <div @click="removeImg(ix)" class="removeupimg">删除</div>
                </div>
                <uploadimg @getimg="getimg" :impose="false" style="margin-right:16px;">
                    <div class="upImg flex alginCenter spacearound">+</div>
                </uploadimg>
            </div>
            <button class="refundSub" @click="aftersaleSub(popup_goods_order)">提交</button>
        </div>
        </el-dialog>

        <!-- 查看快递 -->
        <el-dialog
        title="查看物流"
        :visible.sync="expressHidden"
        width="500px"
        >
        <div style="margin-top:0px;text-align:left;">物流名称：{{orderExpress.express_name}} </div>
        <div style="margin-top:10px;margin-bottom:10px;text-align:left;">物流单号：{{orderExpress.express_num}} </div>
        <div class="scrollHidden" style="height:400px;overflow:auto;">
            <el-steps direction="vertical" :active="9999">
                <el-step icon="el-icon-circle-check" v-for="(im,ix) in orderExpress.list" :key="ix" :title="im.time + im.status" :description="im.context"></el-step>
            </el-steps>
        </div>
        </el-dialog>
    </div>
</template>
<script>
import { order_list, order_detail, cancel_order, order_refund, order_evaluate, order_after, confirm_receipt, get_logistics } from '@/utils/Api/order'
import uploadimg from '@/components/uploadimg'
import tempData from '@/components/tempData'
export default {
    name:'orderList',
    components:{uploadimg, tempData},
    data(){
        return{
            shopstars:0,
            popup_goods_order:{},
            orderTypeList:[
                {
                    title:'全部订单',
                    id: 0
                },
                {
                    title:'待付款',
                    id: 4
                },
                {
                    title:'待发货',
                    id: 1
                },
                {
                    title:'待收货',
                    id: 2
                },
                {
                    title:'已完成',
                    id: 3
                },
            ],
            ordertypeindex:0,
            page: 1,
            orderList:[],
            orderNumber: 0,
            showOrderDetail: false,
            refundShow: false,
            upimgList:[],
            reason:{},
            evaluateInfo:{},
            evaluateShow: false,
            shopcontent:'',
            afterSale:['商品有问题', '少发/漏发', '包装/商品破损', '其他'],
            aftersaleHidden: false,
            afterSaleSel:'',
            orderDetailTotal:'',            // 订单详情中的总应付金额
            orderExpress:{},
            expressHidden: false
        }
    },
    created(){
        this.initial()
    },
    methods:{
        readLog(value){
            get_logistics({order_sn: value.order_sn}).then(res => {
                if(res){
                    this.orderExpress = res
                    this.expressHidden = true
                }
            })
        },
        /* 确认收货 */
        frimShop(value){
            this.$confirm('是否确认收货？',{
                cancelButtonText:'取消',
                confirmButtonText:'确定',
                type: 'warning'
            }).then(()=>{
                confirm_receipt({order_sn: value.order_sn}).then(res => {
                    if(res.length < 1){
                        this.initial()
                        this.showOrderDetail = false
                        this.$message.success('确认收货成功！')
                        return
                    }
                    this.$message.error('确认收货失败')
                })
            })
        },
        /* 打开售后 */
        aftersale(value){
            this.popup_goods_order = value
            this.aftersaleHidden = true
        },
        /* 申请售后 */
        aftersaleSub(value){
            order_after({
                order_sn: value.order_sn,
                reason: this.afterSaleSel,
                reason_describe: this.reason.reason_describe,
                reason_img: this.upimgList.join(',')
            }).then(res => {
                if(res.length < 1){
                    this.afterSaleSel = ''
                    this.reason.reason_describe = ''
                    this.upimgList = ''
                    this.$message.success('申请售后成功！')
                }
            })
        },
        /* 提交评价 */
        evalSub(value){
            order_evaluate({
                id: value.id,
                stars: this.shopstars,
                img: this.upimgList.join(','),
                content: this.shopcontent
            }).then(res => {
                if(res.length < 1){
                    this.shopstars = 0
                    this.upimgList = []
                    this.shopcontent = ''
                    this.initial()
                    this.evaluateShow = false
                    this.$message.success("评价成功!")
                }
            })
        },
        evaluate(value){
            console.log(value)
            this.evaluateInfo = value
            this.evaluateShow = true
        },
        refundSub(value){
            order_refund({
                order_sn: value.order_sn,
                reason: this.reason.reason_describe,
                reason_describe: this.reason.reason_describe,
                reason_img: this.upimgList.join(',')
            }).then(res => {
                if(res.length < 1){
                    value.status = 6
                    this.reason.reason_describe = ''
                    this.upimgList = []
                    this.refundShow = false
                    this.$message.success('申请成功！')
                }
            })
        },
        removeImg(ix){
            this.$confirm('确认删除此图片？',{
                cancelButtonText:'取消',
                confirmButtonText:'确定',
                type: 'warning'
            }).then(()=>{
                this.upimgList.splice(ix,1)
            }).catch(()=>{})
        },
        getimg(value){
            // console.log(value)
            this.upimgList.push(value)
        },
        /* 退款 */
        refund(im){
            this.popup_goods_order = im
            this.refundShow = true
        },
        /* 取消订单 */
        cancleOrder(value){
            let params = value ? value : this.popup_goods_order
            this.$confirm('是否取消此订单？',{
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning'
            }).then(res => {
                cancel_order({order_sn: params.order_sn}).then(canres => {
                    if(canres.length < 1){
                        params.status = 5
                        this.initial()
                        this.$message.success('取消成功！')
                    }
                })
            }).catch(err => {})
        },
        /* 去支付 */
        orderPay(im){
            console.log(im)
            this.goother({path:'/goodslist/ordersuccess', query:{orderNum: im.order_sn,iOnly: im.pay_type == 3 ? true : false, s: 3, orderList: true}})
        },
        /* 查看订单详情 */
        showDetail(im){
            order_detail({order_sn: im.order_sn}).then(res => {
                this.popup_goods_order = res
                this.showOrderDetail = true
                let integ =0, price = 0
                res.goods_order.forEach((im,ix) => {
                    console.log(im,ix)
                    integ += parseFloat(im.integral) * im.num
                    price += parseFloat(im.price) * im.num
                });
                this.orderDetailTotal = `${integ.toFixed(2)}积分 + ￥${(price+parseFloat(res.express)).toFixed(2)}`
            })
        },
        initial(){
            let params = {
                page: this.page,
                limit: 10,
                status: this.orderTypeList[this.ordertypeindex].id
            }
            this.getOrderList(params)
        },
        getOrderList(params){
            order_list(params).then(res => {
                console.log(res)
                if(!res.count){
                    this.orderList = []
                    this.orderNumber = 0
                    return
                }
                this.orderNumber = res.count
                if(this.orderList.length < 1){
                    for(let i = 0; i < Math.ceil(res.count/10); i++){
                        this.orderList.push([])
                    }
                }
                this.orderList[this.page-1] = res.res
                this.$forceUpdate()
            })
        },
        /* 改变页数 */
        currentChange(value){
            this.page = value
            this.initial()
            this.scrollTop()
        }
    },
    watch:{
        ordertypeindex(n ,o){
            this.page = 1
            this.orderList = []
            this.initial()
            this.scrollTop()
        },
        evaluateShow(n){
            if(!n){
                this.shopstars = 0
                this.upimgList = []
                this.shopcontent = ''
            }
        },
        aftersaleHidden(n){
            if(!n){
                this.afterSaleSel = ''
                this.reason.reason_describe = ''
                this.upimgList = []
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.evalSub{
    width: 95px;
    height: 45px;
    opacity: 1;
    background: #47d7e3;
    border-radius: 2px;
    color: #FFFFFF;
    font-size: 17px;
    margin-top: 25px;
    cursor: pointer;
}
.evaluateText{
    margin-top: 20px;
    textarea{
        width: calc(421px - 48px);
        height: calc(123px - 24px);
        padding: 12px 24px;
        font-size: 14px;
        color: #C4C4C4;
        outline: none;
        border: 1px solid #D9D9D9;
    }
}
.shopInfo{
    height: 122px;
    img{
        width: 122px;
        height: 122px;
        border-radius: 8px;
    }
    .shoInfoName{
        margin-left: 25px;
    }
}
.refundSub{
    width: 79px;
    height: 33px;
    opacity: 1;
    background: #ff9500;
    border-radius: 2px;
    font-size: 15px;
    color: #FFFFFF;
    margin-top: 30px;
    cursor: pointer;
}
.orderRefundImg{
    margin-top: 5px;
    margin-right: 16px;
    .orderRefundImgList{
        width: 65px;
        height: 65px;
        // border: 1px solid #c3c3c3;
        margin-right: 15px;
        margin-top: 15px;
        position: relative;
        &:hover{
            .removeupimg{display: inline-block;}
        }
        .removeupimg{
            position: absolute;
            bottom: 0;
            left: 0px;
            width: 100%;
            height: 20px;
            background: rgba($color: #000000, $alpha: .4);
            font-size: 15px;
            color: #FFFFFF;
            text-align: center;
            cursor: pointer;
            display: none;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
    .upImg{
        width: 65px;
        margin-top: 15px;
        height: 65px;
        opacity: 1;
        background: #ffffff;
        border: 1px solid #c3c3c3;
        font-size: 26px;
        line-height: 65px;
        color: #C7C7C7;
        text-align: center;
    }
}
.orderDetail{
    height: 500px;
    width: 910px;
    overflow-x: hidden;
    overflow-y: auto;
    .orderRefund{
        textarea{
            width: calc(100% - 34px);
            height: calc(167px - 36px);
            background: #F5F7F8;
            margin-top: 28px;
            padding: 17px 18px;
            outline: none;
            border: none;
            font-size: 13px;
            color: #A0A0A0;
        }
    }
    .orderGoods{
        width: 100%;
        border: 1px solid #EEEEEE;
        margin-top: 20px;
        text-align: center;
        .orderShopInfo{
            float: right;
            height: 120px;
            text-align: right;
            margin-right: 60px;
            .orderShopInfoBtn{
                margin-top: 25px;
                button{
                    width: 86px;
                    height: 35px;
                    opacity: 1;
                    border: 1px solid #ff0036;
                    border-radius: 5px;
                    color: #FF0036;
                    font-size: 14px;
                    background: none;
                    cursor: pointer;
                    margin-left: 18px;
                }
                .orderPay{
                    border: 1px solid #47d7e3;
                    background: #47d7e3;
                    color: #FFFFFF;
                }
                .orderPaynbg{
                    border: 1px solid #47d7e3;
                    background: none;
                    color: #47d7e3;
                }
            }
            .orderShopInfoPrice{
                margin-top: 38px;
                font-size: 14px;
                color: #333333;
                span{
                    font-size: 14px;
                    color: #F03708;
                }
            }
        }
        .orderGoodsList_goods{
            img{
                margin-left: 44px;
                width: 88px;
                height: 88px;

            }
            .orderGoodsList_goodsname{
                margin-left: 25px;
            }
        }
        .orderGoodsList{
            width: 100%;
            height: 120px;
            border-bottom: 1px solid #DFDFDF;
        }
        .orderGoods_goods{
            width:430px;
            height: 100%;
        }
        .orderGoods_price{
            width: 160px;
            height: 100%;
        }
        .orderGoods_num{
            width: 140px;
            height: 100%;
        }
        .orderGoods_total{
            height: 100%;
            width: 160px;
        }
        .orderGoodsTitle{
            width: 100%;
            height: 40px;
            background: #F9FAFC;
            font-size: 14px;
        }
    }
    .orderDetailInfo{
        .orderDetailInfoPay{
            margin-top: 20px;
            .orderDInfoLeft{
                width: 95px;
                margin-left: 24px;
            }
            .orderDInfoRight{
                font-size: 13px;
                color: #666666;
            }
        }
    }
    .orderDetailTitle{
        width: 100%;
        height: 40px;
        background: #F7FBFF;
    }
    .orderDetailTop{
        width: 100%;
        height: 230px;
        border: 1px solid #f3f3f3;
    }

    .orderInfoBottom{
        flex: 1;
        height: 115px;
        .orderInfoBottomRight{
            font-size: 13px;
            color: #777777;
            flex: 1;
            height: 100px;
            button{
                width: 73px;
                height: 28px;
                opacity: 1;
                background: #47d7e3;
                border-radius: 2px;
                font-size: 13px;
                color: #FFFFFF;
                cursor: pointer;
            }
            span{
                cursor: pointer;
            }
        }
        .orderInfoBottomLeft{
            width: 599px;
            overflow: hidden;
            height: 100%;
            background: #fff;
            border-right: 1px solid #CAEBFC;
            .goods_orderNum{margin-left: 20px;font-size: 12px;}
            .goods_orderPrice{
                width: 130px;
                height: 20px;
                margin-left: 30px;
                font-size: 12px;
            }
            .goods_orderTitle{
                width: 240px;
                margin-left: 20px;
                text-align: left;
                font-size: 13px;
            }
            img{
                width: 80px;
                height: 80px;
                margin-left: 15px;
            }
        }
    }






}
.orderList{
    .orderInfoList{
        width: 916px;
        margin-top: 30px;
        margin-left: 30px;
        .orderInfo{
            width: 916px;
            // height: 157px;
            border: 1px solid #caebfc;
            margin-bottom: 15px;
            margin-top: 15px;
            .orderInfoBottomCenter{
                width: 135px;
                height: 100%;
                border-right: 1px solid #caebfc;
                font-size: 11px;
                color: #333333;
            }
            .orderInfoBottom{
                flex: 1;
                height: 115px;
                .orderInfoBottomRight{
                    font-size: 13px;
                    color: #777777;
                    flex: 1;
                    height: 100px;
                    button{
                        width: 73px;
                        height: 28px;
                        opacity: 1;
                        background: #47d7e3;
                        border-radius: 2px;
                        font-size: 13px;
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                    span{
                        cursor: pointer;
                    }
                }
                .orderInfoBottomLeft{
                    width: 599px;
                    overflow: hidden;
                    height: 100%;
                    background: #fff;
                    border-right: 1px solid #CAEBFC;
                    .goods_orderNum{margin-left: 20px;font-size: 12px;}
                    .goods_orderPrice{
                        width: 130px;
                        height: 20px;
                        margin-left: 30px;
                        font-size: 12px;
                    }
                    .goods_orderTitle{
                        width: 240px;
                        margin-left: 20px;
                        text-align: left;
                        font-size: 13px;
                    }
                    img{
                        width: 80px;
                        height: 80px;
                        margin-left: 15px;
                    }
                }
            }
            .orderInfoTitle{
                width: 100%;
                height: 40px;
                background: #F7FBFF;
                font-size: 13px;
                color: #333333;
                .orderInfoTitleLeft{
                    margin-left: 20px;
                    font-size: 13px;
                }
                .orderInfoTitleRight{
                    margin-right: 22px;
                }
            }
        }
    }
    .orderTypeList{
        width: calc(100% - 30px);
        height: 50px;
        border-bottom: 1px solid #EEEEEE;
        margin-left: 30px;
        .typeCurr{
            color: #FF0036;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                display: inline-block;
                width: 100%;
                border-bottom: 1px solid #FF0036;
                bottom: -16px;
                left: 0;
            }
        }
        span{
            font-size: 16px;
            color: #333333;
            padding-left: 20px;
            padding-right: 20px; 
            font-weight: bold;
            float: left;
            cursor: pointer;
            border-right: 1px solid #DFE4E8;

        }
    }
}
</style>